<template>
  <header
    class="flex flex-wrap lg:items-center lg:rounded-t-4xl bg-white px-8 md:px-18 lg:pt-15"
  >
    <div class="flex w-full">
      <div
        class="inline-flex justify-center items-center w-16 min-h-16 mr-5 rounded-t-lg rounded-br-lg transition duration-200 ease-in-out"
        :class="iconColor"
        v-if="icon"
      >
        <img :src="icon" class="w-[35px] h-[35px]" />
      </div>
      <div class="flex-1 my-auto md:flex md:items-center md:h-full flex-wrap">
        <h1 class="pr-5.5 w-full" :class="{ [titleTextColor]: titleTextColor, 'md:pl-10 lg:pl-4.5': icon }">
          {{ title }}
        </h1>
        <h3
          v-if="subtitle"
          class="block w-full"
          :class="{ [titleTextColor]: titleTextColor, 'md:pl-10 lg:pl-4.5': icon }"
        >
          {{ subtitle }}
        </h3>
      </div>
    </div>
    <section-share-social v-if="shareable"></section-share-social>
    <div v-else class="h-px w-full my-7.5 bg-sable"></div>
  </header>
</template>

<script>
import SectionShareSocial from "~/components/Sections/SectionShareSocial";

export default {
  name: "HeaderCategory",
  props: {
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    subtitle: {
      type: String,
      default: undefined,
    },
    titleTextColor: {
      type: String,
      required: false,
      default: "text-black",
    },
    icon: {
      type: String,
      required: false,
      default: "",
    },
    iconColor: {
      type: String,
      required: true,
    },
    shareable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    SectionShareSocial,
  },
};
</script>

<style lang="scss" scoped>
.min-w-50px {
  min-width: 50px;
}
.min-h-50px {
  min-height: 50px;
  height: 50px;
  max-height: 50px;
}
</style>
